import React, { FC, RefObject } from 'react';
import { Button, Card, Col, Form, InputNumber, Layout, Row, Select, Space, Typography } from 'antd';
import { FileImageOutlined, PlusOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { message, Upload } from 'antd';
import 'antd/dist/reset.css';
import './App.css';
import { Content, Header } from 'antd/es/layout/layout';
import Dragger, { DraggerProps } from 'antd/es/upload/Dragger';
import { RcFile } from 'antd/es/upload';

// let photon: any = null;
// import("@silvia-odwyer/photon").then(p => {
//     photon = p;
// });

// import * as photon from "@silvia-odwyer/photon";

// import * as wasm from "@silvia-odwyer/photon/photon_rs_bg.wasm";
// export * from "@silvia-odwyer/photon/photon_rs_bg.js";

// const loadWasm = async () => {
//     return await import('@silvia-odwyer/photon');
// }
// const { base64_to_image } = await import("@silvia-odwyer/photon")


const loadWasm = async () => {
    let wasm;

    try {
        const photon = await import('@silvia-odwyer/photon');

        wasm = photon;

    } finally {
        console.log("loaded wasm successfully");
    }

    return wasm;
}

const sizes = [
    {
        value: "512x512",
        label: "512x512",
    },
    {
        value: "1024x1024",
        label: "1024x1024",
    },
    {
        value: "1920x1080",
        label: "1080p (1920x1080)"
    },
    {
        value: "3840x2160",
        label: "4k (3840x2160)"
    },
    {
        value: "custom",
        label: "Custom"
    }
]

class App extends React.Component {
    upload: RefObject<DraggerProps>;

    constructor(props: any) {
        super(props);
        this.upload = React.createRef();
    }

    state = {
        selection: "1920x1080",
        width: 1920,
        height: 1080,
        fileBlobs: [] as Array<RcFile>,
        resizing: false,
    }

    updateSelection = (value: string) => {
        this.setState({ selection: value, });

        if (value === "custom")
            return;

        this.setState({
            width: parseInt(value.split("x")[0]),
            height: parseInt(value.split("x")[1]),
        });
    }

    resize = async () => {
        if (this.upload === undefined || this.upload.current === undefined || this.upload.current?.fileList === undefined) {
            console.error("This.upload is undefined");
            return;
        }

        this.setState({ resizing: true });

        await new Promise(r => setTimeout(r, 350));


        let num_files = this.upload.current?.fileList?.length;
        for (let i = 0; i < num_files; i++) {
            console.log(`Processing ${i}/${num_files}`)
            console.log(`num_files ${num_files} vs blobs ${this.state.fileBlobs}`);

            let file = this.state.fileBlobs.at(-1) as RcFile;
            let reader = new FileReader();

            let load = new Promise((resolve, reject) => {
                try {
                    const resize_file = async (e: ProgressEvent<FileReader>) => {
                        console.log(`Resizing file ${file.name}`);
                        const Photon = await loadWasm();
                        let canvas = document.getElementById("canvas") as HTMLCanvasElement;
                        let ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
                        let img = new Image();
                        img.onload = () => {
                            canvas.width = img.width;
                            canvas.height = img.height;

                            ctx?.drawImage(img, 0, 0);
                            let image = Photon.open_image(canvas, ctx);
                            let method = 3;
                            if (img.width > this.state.width || img.height > this.state.height) {
                                method = 5;
                            }
                            let resized = Photon.resize(image, this.state.width, this.state.height, method);

                            let data = Photon.to_image_data(resized) as ImageData;
                            ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

                            canvas.width = data.width;
                            canvas.height = data.height;
                            ctx?.putImageData(data, 0, 0);

                            let link = document.createElement("a");
                            link.download = (file.name.substring(0, file.name.lastIndexOf(".")) || file.name) + "_" + data.width + "x" + data.height + (file.name.substring(file.name.lastIndexOf("."), file.name.length) || "");
                            link.href = canvas.toDataURL();
                            link.click();
                            resolve(true);
                        };
                        img.src = e.target?.result as string;
                    }

                    reader.addEventListener("load", (e) => resize_file(e));

                    reader.readAsDataURL(file);
                } catch (err) {
                    reject(err);
                }
            });

            await Promise.resolve(load);

            this.upload.current?.fileList?.pop();

            // debugger;
            // let fileBlobs = this.state.fileBlobs;
            // fileBlobs.shift();
            // this.setState({ fileBlobs });

            this.setState({ fileBlobs: this.state.fileBlobs.slice(0, this.state.fileBlobs.length - 1) });
            this.state.fileBlobs = this.state.fileBlobs.slice(0, this.state.fileBlobs.length - 1);

        }
        this.setState({ resizing: false });
    }


    render() {
        const props: UploadProps = {
            accept: "image/png, image/gif, image/jpeg",
            multiple: true,
            listType: "picture",
            beforeUpload: (file) => {
                let blobs = this.state.fileBlobs;
                blobs.push(file);
                this.setState({ fileBlobs: blobs });
                // this.setState({ fileBlobs: [...this.state.fileBlobs, file] });
                // console.log(file, this.state.fileBlobs, [...this.state.fileBlobs, file]);
                console.log(this.state.fileBlobs);
                return false;
            },
            onRemove: (file) => {
                this.setState({ fileBlobs: this.state.fileBlobs.filter(f => f !== file) });
                console.log(this.state.fileBlobs.filter(f => f.uid !== file.uid))
            },
        };

        return (
            <div className="App">
                <Layout style={{ backgroundColor: "white" }}>
                    <Header style={{ backgroundColor: "white", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h1 style={{ textAlign: "left", display: "inline-block" }}>Resizy</h1>
                        <Typography.Text style={{ textAlign: "right" }}>Fast and secure image resizing, without files ever leaving your computer.</Typography.Text>

                    </Header>
                </Layout>
                <div style={{ paddingTop: "4rem" }} className="center">
                    <div style={{ display: "flex", alignItems: "start", justifyContent: "start" }}>
                        <Card>
                            <Space direction='vertical'>
                                <Dragger {...props} ref={this.upload}>
                                    <p className="ant-upload-drag-icon" style={{ marginTop: "1rem" }}>
                                        <FileImageOutlined />
                                    </p>
                                    <p className="ant-upload-text" style={{ margin: "0.5rem 8rem 1rem" }}>Click or drag to upload</p>
                                </Dragger>
                                <Form layout='vertical' name="form" style={{ paddingTop: "2rem" }} onFinish={this.resize}>
                                    <Row>
                                        <Col span={11}>
                                            <Form.Item
                                                label="Size"
                                            >
                                                <Select options={sizes} value={this.state.selection} style={{ width: "100%" }} onChange={this.updateSelection}></Select>

                                            </Form.Item>
                                        </Col>
                                        <Col span={2} className="center-vh">
                                            <Form.Item label=" " name="1">
                                                <Typography.Text type="secondary">or</Typography.Text>
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item label=" ">
                                                <Row>
                                                    <Col span="11">
                                                        <InputNumber style={{ width: "100%" }} value={this.state.width} onChange={(width) => this.setState({ width, selection: "custom" })} />
                                                    </Col>
                                                    <Col span="2" className="center-vh">
                                                        <PlusOutlined rotate={45} style={{ color: "rgba(0, 0, 0, 0.45)" }} />
                                                    </Col>
                                                    <Col span="11">
                                                        <InputNumber style={{ width: "100%" }} value={this.state.height} onChange={(height) => this.setState({ height, selection: "custom" })} />
                                                    </Col>
                                                </Row>
                                            </Form.Item>

                                        </Col>
                                    </Row>
                                    <Form.Item>
                                        <Button type='primary' htmlType='submit' size="large" style={{ width: "100%" }} disabled={this.state.fileBlobs.length <= 0} loading={this.state.resizing}>
                                            Resize
                                        </Button>
                                    </Form.Item>
                                </Form>

                            </Space></Card>
                    </div>
                </div>
                <canvas id="canvas" style={{ display: "none" }}></canvas>
            </div >
        )
    }
};

export default App;
